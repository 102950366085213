<template>
  <div class="integral">
    <headbox
      title="我的积分"
      bgColor="#f94440"
      iswhite="true"
      :isbackPath="true"
      path="person"
    ></headbox>
    <div class="body">
      <div class="total flex-between">
        <div class="name">当前可用积分: <span>{{ userInfo.integral }}</span></div>
        <el-button class="changeBtn" :disabled="userInfo.integral < 100" :style="{opacity: userInfo.integral < 100 ? '0.5' : '1'}" @click="showIntegralAfter = true">兑换</el-button>
        <el-button class="changeBtn" @click="goIntegralRule" style="width: 100px; margin-left: -20px">查看积分规则</el-button>
      </div>
      <!-- 积分明细 -->
      <div style="background: #ffffff">
        <div v-if="ishow">
          <van-empty
            class="custom-image"
            :image="require('@/assets/images/pic_no information@2x.png')"
            description="暂无数据!"
          />
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="load"
          v-if="!ishow"
        >
          <div class="list">
            <div
              class="listContent flex"
            >
              <div class="time">时间</div>
              <div class="name" style="width: 80px">原积分</div>
              <div class="name" style="width: 80px">变动积分</div>
              <div class="name" style="width: 100px">积分类型</div>
            </div>
            <div
              class="listContent flex"
              v-for="(item, index) in integralList"
              :key="index"
            >
              <div class="time">{{ item.created | formatDate_("yyyy-MM-dd") }}</div>
              <div class="name" style="width: 80px">{{ item.beforeIntegral }}</div>
              <div class="name" style="width: 80px">{{ item.integral }}</div>
              <div class="name" style="width: 100px">{{ IntegralType[item.integralType] }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <integralAfter :showIntegralAfter.sync="showIntegralAfter" :freeIntegral="userInfo.integral" ref="integralPop"/>
  </div>
</template>

<script>
import headbox from "@/components/head";
import integralAfter from "@/components/integralAfter"
import { formatDate_ } from "@/utils/filters";
import { getIntegral } from "@/utils/api";

const IntegralType =  {
  SELL: '卖料结算', // 卖料结算单
  BUY: '买料结算', // 买料结算单
  VIOLATE: '延期卖料结算', // 延期卖出结算
  BUY_VIOLATE: '延期买料结算', // 延期买入结算
  REBACK: '取消结算退回', // 取消结算单退回
  FIRST_REWARD: '好友首次奖励', // 好友首次奖励
  REWARD: '好友交易奖励', // 好友交易奖励
  CHANGE: '兑换', // 兑换
  DEDUCT: '扣除', // 扣除
}

export default {
  components: {
    headbox,
    integralAfter
  },
  data() {
    return {
      userInfo: {}, //用户信息
      integralList: [], // 积分明细列表
      loading: false,
      finished: false,
      page: 0,
      size: 10,
      ishow: true, // 没有数据显示的
      IntegralType:  {
        SELL: '卖料结算', // 卖料结算单
        BUY: '买料结算', // 买料结算单
        PRE_MORTGAGE: '存料结算', // 存料结算单
        VIOLATE: '延期卖料结算', // 延期卖出结算
        BUY_VIOLATE: '延期买料结算', // 延期买入结算
        REBACK: '取消结算退回', // 取消结算单退回
        FIRST_REWARD: '好友首次奖励', // 好友首次奖励
        REWARD: '好友交易奖励', // 好友交易奖励
        CHANGE: '兑换', // 兑换
        DEDUCT: '扣除', // 扣除
      },
      showIntegralAfter: false
    };
  },
  filters: {
    formatDate_
  },
  created() {
    this.load();
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
  },
  methods: {
    async load() {
      try {
        const res = await getIntegral({
          page: this.page,
          size: this.size
        });
        this.page = res.number + 1;
        this.integralList.push(...res.content);
        this.loading = false;
        if (this.integralList.length != 0) {
          this.ishow = false;
        } else {
          this.ishow = true;
        }
        if (res.last === true) {
          this.finished = true;
        }
      } catch {
        this.ishow = true;
      }
    },
    reload() {
      this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
      this.page = 0
      this.integralList = []
      this.load()
    },
    goIntegralRule() {
      this.$router.push({ path: "/integralRule" });
    }
  }
}
</script>

<style lang="scss" scoped="true">
::v-deep {
  .van-empty__description {
    //color: #ffffff;
    font-size: 24px;
  }
  .van-list__finished-text {
    color: #ffffff;
  }
}
.integral {
  background-color: #f5f5f5;
  height: 100vh;
  .body {
    .total{
      overflow: hidden;
      margin: 30px 0;
      background: #ffffff;
      h2{
        color:#FF4B71;
        font-weight: 600;
        font-size: 44px;
        margin-top: 34px;
      }
      .name{
        font-size: 30px;
        color:#242424;
        margin: 21px 24px 30px;
      }
      span {
        color:#FF4B71;
      }
      .changeBtn {
        margin: 21px 24px 30px;
        background: #FF2D2E;
        width: 140px;
        height: 50px;
        color: #fff;
        font-size: 24px;
        border-radius: 32px;
        border: none;
      }
    }
    .costCalculation{
      padding: 18px 20px;
      display: flex;
      background-color: #ffffff;
      .icon{
        width: 30px;
        height:30px;
        border-radius: 50%;
        background-color: #ff2d2e;
        margin-right: 20px;
      }
      h3{
        font-size: 28px;
        color: #ccc;
      }
    }
    .list {
      background: #ffffff;
      .flex_between {
        display: flex;
        justify-content: space-between;
      }
      .listContent {
        padding: 0 16px;
        height: 67px;
        line-height: 67px;
        border-bottom: 1px solid #d8d8d8;
        text-align: center;
        font-size: 32px;
        .time {
          text-align: center;
          width: 198px;
        }
        .name {
          text-align: center;
          width: 140px;
        }
      }
    }
  }
}
</style>
