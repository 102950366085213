var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "integralAfter" },
    [
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false, "get-container": "#app" },
          model: {
            value: _vm.showIntegralAfter,
            callback: function($$v) {
              _vm.showIntegralAfter = $$v
            },
            expression: "showIntegralAfter"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("积分兑换")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _c(
              "div",
              { staticClass: "flex orderInfo topCol" },
              [
                _c("van-field", {
                  attrs: {
                    label: "使用积分",
                    placeholder: "请输入要兑换的积分",
                    "input-align": "right",
                    size: "24px",
                    type: "number"
                  },
                  model: {
                    value: _vm.integral,
                    callback: function($$v) {
                      _vm.integral = $$v
                    },
                    expression: "integral"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "tips" }, [
              _vm._v(" 注：请输入100的倍数(100积分=1元) ")
            ])
          ]),
          _c("div", { staticClass: "flex popbtn" }, [
            _c(
              "div",
              { staticClass: "gocer closebtn", on: { click: _vm.close } },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              { staticClass: "gocer confirmbtn", on: { click: _vm.confirm } },
              [_vm._v("确定")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }