var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "integral" },
    [
      _c("headbox", {
        attrs: {
          title: "我的积分",
          bgColor: "#f94440",
          iswhite: "true",
          isbackPath: true,
          path: "person"
        }
      }),
      _c("div", { staticClass: "body" }, [
        _c(
          "div",
          { staticClass: "total flex-between" },
          [
            _c("div", { staticClass: "name" }, [
              _vm._v("当前可用积分: "),
              _c("span", [_vm._v(_vm._s(_vm.userInfo.integral))])
            ]),
            _c(
              "el-button",
              {
                staticClass: "changeBtn",
                style: { opacity: _vm.userInfo.integral < 100 ? "0.5" : "1" },
                attrs: { disabled: _vm.userInfo.integral < 100 },
                on: {
                  click: function($event) {
                    _vm.showIntegralAfter = true
                  }
                }
              },
              [_vm._v("兑换")]
            ),
            _c(
              "el-button",
              {
                staticClass: "changeBtn",
                staticStyle: { width: "100px", "margin-left": "-20px" },
                on: { click: _vm.goIntegralRule }
              },
              [_vm._v("查看积分规则")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { background: "#ffffff" } },
          [
            _vm.ishow
              ? _c(
                  "div",
                  [
                    _c("van-empty", {
                      staticClass: "custom-image",
                      attrs: {
                        image: require("@/assets/images/pic_no information@2x.png"),
                        description: "暂无数据!"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.ishow
              ? _c(
                  "van-list",
                  {
                    attrs: {
                      finished: _vm.finished,
                      "finished-text": "没有更多了"
                    },
                    on: { load: _vm.load },
                    model: {
                      value: _vm.loading,
                      callback: function($$v) {
                        _vm.loading = $$v
                      },
                      expression: "loading"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "list" },
                      [
                        _c("div", { staticClass: "listContent flex" }, [
                          _c("div", { staticClass: "time" }, [_vm._v("时间")]),
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              staticStyle: { width: "80px" }
                            },
                            [_vm._v("原积分")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              staticStyle: { width: "80px" }
                            },
                            [_vm._v("变动积分")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              staticStyle: { width: "100px" }
                            },
                            [_vm._v("积分类型")]
                          )
                        ]),
                        _vm._l(_vm.integralList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "listContent flex" },
                            [
                              _c("div", { staticClass: "time" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate_")(
                                      item.created,
                                      "yyyy-MM-dd"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  staticStyle: { width: "80px" }
                                },
                                [_vm._v(_vm._s(item.beforeIntegral))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  staticStyle: { width: "80px" }
                                },
                                [_vm._v(_vm._s(item.integral))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "name",
                                  staticStyle: { width: "100px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.IntegralType[item.integralType])
                                  )
                                ]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("integralAfter", {
        ref: "integralPop",
        attrs: {
          showIntegralAfter: _vm.showIntegralAfter,
          freeIntegral: _vm.userInfo.integral
        },
        on: {
          "update:showIntegralAfter": function($event) {
            _vm.showIntegralAfter = $event
          },
          "update:show-integral-after": function($event) {
            _vm.showIntegralAfter = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }