<template>
  <div class="integralAfter">
    <van-dialog
      v-model="showIntegralAfter"
      :show-confirm-button="false"
      get-container="#app"
    >
      <div class="title">积分兑换</div>
      <div class="centerbox_dialog">
        <div class="flex orderInfo topCol">
          <van-field
            v-model="integral"
            label="使用积分"
            placeholder="请输入要兑换的积分"
            input-align="right"
            size="24px"
            type="number"
          />
        </div>
        <div class="tips">
          注：请输入100的倍数(100积分=1元)
        </div>
      </div>
      <div class="flex popbtn">
        <div class="gocer closebtn" @click="close">取消</div>
        <div class="gocer confirmbtn" @click="confirm">确定</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import * as api from "@/utils/api";

export default {
  props: ["showIntegralAfter", "freeIntegral"],
  inject: ["reload"],
  data() {
    return {
      integral: ""
    };
  },
  methods: {
    // 取消按钮点击事件
    close() {
      // 通知父组件关闭弹窗
      this.$emit("update:showIntegralAfter", false);
    },
    async confirm() {
      if (!this.integral) {
        return this.$toast("请输入要兑换的积分"), !1;
      }
      if (Number(this.integral) > this.freeIntegral || Number(this.integral) % 100 !== 0) {
        return this.$toast("积分输入有误"), !1;
      }
      this.$emit("update:showIntegralAfter", false);
      await api.changeIntegral({
        integral: this.integral,
        type: "INTEGRAL_CHANGE"
      });
      const user = await api.getuser()
      localStorage.setItem('userinfo',JSON.stringify(user))
      this.$toast({
        type: "success",
        message: "兑换成功",
        forbidClick: true,
        onClose: async() => {
          this.reload()
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.van-dialog {
  width: 90% !important;
  padding: 0 40px 30px;
  .title {
    color: #242424;
  }
  .van-field {
    font-size: 28px;
  }
  .van-cell {
    padding: 10px 0 0 !important;
    line-height: 60px !important;
  }
}

.popbtn {
  margin-top: 25px;
  div {
    margin-top: 5px !important;
    border-top: none !important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }
}
.title {
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
//.orderInfo{
//    padding: 4px 0;
//}
.gocer {
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color: #fff;
  text-align: center;
}
.centerbox_dialog {
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
.closebtn {
  background-color: #d1d1d1;
}
.confirmbtn {
  background-color: #ff2d2e;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.topCol {
  color: #646566;
  line-height: 60px;
  border-bottom: 1px solid #c1c1c1;
}
.btmCol {
  color: #242424;
  line-height: 60px;
}
.tips {
  margin-top: 14px;
  color: #ff2d2e;
  font-size: 20px;
}
</style>
